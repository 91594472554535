/**
 * Main script
 *
 * @package     Burenigma
 * @author      Lucas Fridez
 * @version     v1.0
 */

import { NetworkManager, MethodRequest_e } from "./model/NetworkManager";

// General components
const menuTriggerOpen: HTMLSpanElement = <HTMLSpanElement>document.getElementById("trigger-menu-open");
const menuTriggerClose: HTMLSpanElement = <HTMLSpanElement>document.getElementById("trigger-menu-close");
const nav: HTMLElement = <HTMLElement>document.getElementById("nav");
const overlay: HTMLDivElement = <HTMLDivElement>document.getElementById("overlay");

/**
 * Init events on corresponding page (according to URL)
 * DOM is ready !
 * @author Lucas Fridez
 */
document.addEventListener("DOMContentLoaded", function () {

    // Enable open menu on mobile
    if (menuTriggerOpen) {
        menuTriggerOpen.addEventListener("click", () => {
            nav.classList.add("open");
            overlay.classList.add("visible");
        });
    }

    // Enable close menu on mobile
    if (menuTriggerClose) {
        menuTriggerClose.addEventListener("click", () => {
            nav.classList.remove("open");
            overlay.classList.remove("visible");
        });
    }
});

